// @import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Plus+Jakarta+Sans:wght@200;400;500;600;700;800&display=swap');
@import '_media.scss';
@import '_variables.scss';

.body {
  margin: 0px;
  padding: 0px;
  line-height: 1;
  color: $black;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

.root {
  * {
    // margin: 0;
    // padding: 0;
    // border: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
    scroll-behavior: smooth;
    box-sizing: border-box;
  }
  .date::-webkit-inner-spin-button,
  .date::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
  }

  .h1 {
    font-size: 50px;
    line-height: 1.06;
    font-weight: 800;
    @include tab {
      font-size: 48px;
    }
    @include phone {
      font-size: 38px;
    }
  }

  .h2 {
    font-size: 35px;
    font-weight: 800;
    line-height: 1.17;

    @include phone-m {
      font-size: 42px;
    }
  }

  .h3 {
    font-size: 28px;
    font-weight: 800;
    line-height: 1.18;

    @include phone-m {
      font-size: 34px;
    }
  }

  .h4 {
    font-size: 22px;
    font-weight: 800;
    line-height: 1.33;

    @include phone-m {
      font-size: 24px;
    }
  }

  .h5 {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.3;

    @include phone-m {
      font-size: 22px;
    }
  }

  .h6 {
    font-size: 24px;
    line-height: 1.33;
    font-weight: 400;

    @include phone-m {
      font-size: 18px;
    }
    b {
      font-weight: 600;
    }
  }
  .p {
    font-size: 20px;
    line-height: 1.5;
    color: $grey-3;

    @include tabv-m {
      font-size: 18px;
    }
    // b{
    //   font-weight: 600
    // }
  }
  .img {
    max-width: 100%;
  }
  .social_links {
    list-style: none;
  }

  .container {
    max-width: 1344px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    &.inner {
      max-width: 1116px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    &.center {
      align-items: center;
    }
    &.spaceb {
      justify-content: space-between;
    }
    &.align_end {
      align-items: flex-end;
    }
  }
  .w100 {
    width: 100%;
  }
  .text_center {
    text-align: center;
  }
  @mixin btn(
    $padding: 14px 22px,
    $fs: 16px,
    $fw: 500,
    $bg: transparent,
    $color: $grey-2,
    $box: 0px 0px 0px rgba(0, 0, 0, 0.04),
    $hc: $white,
    $hbg: $grey-2,
    $hbor: $grey-2
  ) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    outline: none;
    font-weight: $fw;
    font-size: $fs;
    padding: $padding;
    background: $bg;
    color: $color;
    box-shadow: $box;
    transition: all ease 250ms;
    line-height: 1.4;
    border-radius: 50px;
    border: 2px solid transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-decoration: none;

    @include phonev {
      width: 100%;
      font-size: 14px;
      padding: 5px 15px;
    }

    &:active {
      outline: none;
      border-color: $grey-2;
      background: $bg !important;
    }

    &:hover {
      outline: none;
      color: $hc;
      background: $hbg;
      box-shadow: 0px 6px 20px rgba(55, 108, 243, 0.1);
      border-color: $hbor;
    }
  }

  .btn {
    @include btn();
    border-color: $grey-1;
  }

  .btn_accent {
    @include btn(
      $bg: $accent,
      $color: $white,
      $hbg: $accent,
      $hbor: $accent,
      $hc: $white
    );
  }

  .header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1040;
    padding: 40px 20px;
    transition: all ease 300ms;

    @include phone {
      padding: 15px;
    }

    .container {
      position: relative;
      z-index: 10;
    }

    .backdrop_blur {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: -1;
      transition: all cubic-bezier(0.17, 0.28, 0.6, 1.04) 200ms;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(70px) saturate(2.1);
      opacity: 0;
      will-change: opacity;
    }

    &.fixed {
      padding: 8px 15px;
      box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.08);

      @include phone-m {
        padding: 20px;
      }

      .backdrop_blur {
        opacity: 1;
      }
    }
    .logo_wrpr {
      @include phone {
        max-width: 130px;
      }
      @include phonev {
        max-width: 100px;
      }
      .logo_blk img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .links_wrpr {
      .phone_link {
        margin-right: 16px;
        text-decoration: none;
        color: $grey-2;
        @include phone {
          display: none;
        }
      }
      .btn {
        margin: 0px 16px;
        @include phone {
          margin: 0px 6px;
          padding: 5px 15px;
          width: auto;
        }
      }
      .btn_accent {
        @include phone {
          padding: 5px 15px;
          width: auto;
        }
      }
    }
  }
  .ham_btn {
    font-size: 13px;
    background-color: transparent;
    border: 1px solid transparent;
    vertical-align: -8px;
    outline: none !important;
    position: relative;
    z-index: 10;
    margin-top: 0 !important;
    margin-left: 15px !important;
    padding: 7px 15px !important;
    border-radius: 4px;
    vertical-align: middle;

    @include phone {
      padding: 5px 10px !important;
    }

    span {
      position: relative;
      height: 2px;
      width: 22px;
      display: block;
      background-color: $black;
      margin: 5px 0;
      top: 0;
      bottom: 0;
      transition: all ease 220ms;
    }

    &.active {
      background-color: whitesmoke;
      border-color: whitesmoke;
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 8px;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: -6px;
        }
      }
    }

    @include tabv-m {
      display: none;
    }
  }

  .social_links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 48px 0 0px;
    li:last-child {
      .social_link {
        margin-right: 0px;
      }
    }

    .social_link {
      margin-right: 24px;
      width: 36px;
      height: 36px;
      background: $grey-2;
      border-radius: 50px;
      display: block;
      transition: all ease 200ms;

      &:hover {
        filter: brightness(0.9);
        opacity: 0.9;
      }
    }
  }
  /** common **/

  .rating_sec {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .img {
      margin-right: 10px;
      @include phone {
        max-width: 90px;
      }
    }
    .p {
      font-size: 14px;
    }
  }
  .rb_g {
    background: #263a52;
    background-clip: text;
    background: linear-gradient(90deg, #112135 1%, #f84069 94.18%);
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }
  .title {
    margin-bottom: 24px;
    letter-spacing: -1px;
  }
  .link {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.3px;
    color: $accent;
    text-decoration: none;
    .link_arrow {
      margin-left: 8px;
      display: inline-block;
      transform: translateX(0px);
      transition: all 0.3s $transs;
    }
    &:hover {
      color: $accent;
      .link_arrow {
        transform: translateX(5px);
        transition: all 0.3s $transs;
      }
    }
  }
  .heading_tag {
    background: #faf3ef;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 28px;
    width: fit-content;
    color: #f84069;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: -0.3px;
    margin-bottom: 24px;
    @include phone {
      padding: 7px 20px;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .heading_blk {
    .h2 {
      letter-spacing: -0.01em;
      color: $grey-2;
      font-weight: 700;
      @include phone {
        margin-bottom: 12px;
      }
    }
    br {
      @include phone {
        display: none;
      }
    }
  }
  .form_control {
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(207, 200, 196, 0.3);
    box-shadow: 0px 24px 68px rgba(208, 192, 182, 0.2);
    border-radius: 24px;
    padding: 22px 26px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.44;
    letter-spacing: -0.3px;
    color: $grey-2;
    appearance: none;
    box-sizing: border-box;
    outline: none !important;
    resize: none;
    cursor: pointer;
    @include phone {
      padding: 16px 20px;
      font-size: 14px;
      border-radius: 14px;
    }
    &::placeholder {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.44;
      letter-spacing: -0.3px;
      color: $grey-5;
    }
    &.mb_18 {
      margin-bottom: 18px;
    }
  }
}
